import React from "react"
import { Link } from "gatsby"

import logo from "../../img/logo.svg"
import fbLogo from "../../img/social/facebook.svg"
import twitterLogo from "../../img/social/twitter.svg"
import "./Footer.scss"


class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer__main">
          <Link className="footer__logo-link" title="Home" to="/">
            <img src={logo} className="footer__logo" alt="Trusty Canary" height="30px"/> Trusty Canary
          </Link>
        </div>
        <div className="footer__subfooter">
          <nav className="sub-nav">
            <ul className="sub-nav__menu">
              <li className="sub-nav__menu-item">
                <Link to="/privacy" className="sub-nav__link">
                  Privacy
                </Link>
              </li>
              <li className="sub-nav__menu-item">
                <Link to="/site-terms" className="sub-nav__link">
                  Site Terms
                </Link>
              </li>
            </ul>
          </nav>
          <div className="social-links">
            <a href="https://www.facebook.com/trustycanary/" target="_blank" rel="noopener noreferrer">
              <img src={fbLogo} alt="Trusty Canary on Facebook" height="20px"/>
            </a>
            <a href="https://twitter.com/trustycanary" target="_blank" rel="noopener noreferrer">
              <img src={twitterLogo} alt="Trusty Canary on Twitter" height="20px"/>
            </a>
          </div>
          <span>
            Trusty Canary &copy; {(new Date().getFullYear())}
          </span>
        </div>
      </footer>
    )
  }
}


export default Footer
