import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

import Footer from "../Footer/Footer"
import Navbar from "../Navbar/Navbar"
import "../../styles/main.scss"

import useSiteMetadata from "../SiteMetadata"
import "./Layout.scss"


const TemplateWrapper = ({ children }) => {
  const { title, description, siteUrl, fbAppId } = useSiteMetadata()
  return (
    <div className="layout">
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta property="fb:app_id" content={fbAppId}/>
        <meta name="description" content={description}/>
        <meta property="og:description" content={description}/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content={title}/>
        <meta property="og:url" content={`${siteUrl}/`}/>
        <meta property="og:image" content={`${siteUrl}/img/og-image.png`}/>
        <meta property="og:image:height" content="1200"/>
        <meta property="og:image:width" content="1200"/>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href="/img/safari-pinned-tab.svg"
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <link href="https://fonts.googleapis.com/css?family=Architects+Daughter|Montserrat&display=swap" rel="stylesheet"/>
      </Helmet>
      <Navbar />
      <div className="content">{children}</div>
      <Footer />
      <CookieConsent
        style={
          {
            color: "#222222",
            backgroundColor: "#ffffff",
            borderTop: "solid 1px #f0f0f0",
            boxShadow: "0 -3px 3px rgba(34, 34, 34, 0.25)",
          }
        }
        buttonStyle={
          {
            backgroundColor: "#229922",
            color: "#ffffff",
            fontSize: "16px",
            padding: "10px 15px",
            borderRadius: "5px",
          }
        }
        buttonText="I Understand"
      >
        This website uses cookies to help us improve your experience.
        Check our <Link to="/privacy" className="link">Privacy Policy</Link> for more information.
      </CookieConsent>
    </div>
  )
}


TemplateWrapper.propTypes = {
  children: PropTypes.node
}


export default TemplateWrapper
